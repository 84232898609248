// Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins');

// Variables
@import 'variables';


// Bootstrap
@import '~bootstrap/scss/bootstrap';
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;

}
.header {
    &__brand {
        display: inline-block;
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
    }
    &__logo {
        max-height: 20px;
    }
    .lang{
        &:hover .lang__dropdown-content {
            display: block;
        }
        &__drop-btn {
            width: 90px;
            font-size: 16px;
            cursor: pointer;
            border: 1px solid rgba(207,225,237,.3);
            border-radius: 20px;
            background-color: #fff;
        }

        &__dropdown-content {
            width: 90px;
            display: none;
            position: absolute;
            background-color: #fff;
            z-index: 1;
            border: 1px solid rgba(207,225,237,.3);
            border-radius: 20px;
            padding-left: 8px;
        }
        &__logo {
            width: 20px;
            margin-bottom: 4px;
            vertical-align: middle;
        }
        &__arrow {
            width: 20px;
            vertical-align: middle;
            vertical-align: -webkit-baseline-middle;
        }
        &__btn {
            background-color: transparent;
            border: none;
        }
    }
}
